import React, { useState, useEffect, useRef, useContext, } from 'react';
import {APIProvider, Map, AdvancedMarker, InfoWindow, } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { Button, Select, message, } from 'antd';
import { ClearOutlined, SearchOutlined, } from '@ant-design/icons';
import MarkerColor from '../Components/MarkerColor';
import MarkerGroupColor from '../Components/MarkerGroupColor';
import DevicesStatus from './DevicesStatus';
import { UserContext } from './UserContext';
import "./ShowMap.css";

export default function ShowMap2() {
  const { loggedIn, setLoggedIn, userName } = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const lastActiveTimeRef = useRef(Date.now());

  //This function check if there is activity in the app...if the app not in focus for 15 minutes we are disconecting from the server.
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User put computer to sleep, reset last active time
        lastActiveTimeRef.current = Date.now();// we take the last time it happaned
      } else {
        // User woke computer up, check for inactivity
        const inactiveTime = Date.now() - lastActiveTimeRef.current;//we calculating the duration it went to sleep...
        if (inactiveTime >= 900000) {//if the sleep duration was above 15 minutes we disconnecting the user
           deleteInformation();//Deleting the data
           message.error(<p style={{fontWeight: "bold"}}>Disconnected: The system lost connection to the server...please reconnect.</p>,15);
          // User inactive for 15 min, log out the user
          setIsLoggedIn(false);
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isLoggedIn]);

    //SHOW US THE MARKERS ON THE MAP
    const [showMarkers, setShowMarkers] = useState(true);
    const [zoom, setZoom] = useState(7);
    const [center, setCenter] = useState( ({lat: 32.096234, lng: 34.847722}));
    const [devicesArray, setDevicesArray] = useState([]);
    const [devicesImeiArray, setDevicesImeiArray] = useState([]);
    
    // Here we will store all the groups that exists from the API request
    // const [groupsNames, setGroupsNames] = useState([]);

    //*** */ TEMP HARD ENCODED GROUPS NAMES ARRAY****
    // const [groupsNames2, setGroupsNames2] = useState(["TunnelTest", "BNetTest", "UaeTest","MobileTest"]);
    const [groupsNames2, setGroupsNames2] = useState(["TunnelTest", "BNetTest", "UaeTest", "MobileTest"]);
    const [groupsForGuestNames, setGroupsForGuestNames2] = useState(["ARMONIC"]);
  
    //Here we will store the chosen group, and then send it to the getImeiByGroupNameAndPassword function ;
    const [chosenGroup, setChosenGroup] = useState("");
    
    //Here we will save the password from the user ;
    const [password, setPassword] = useState("");
    
    const[latitudeAverage, setLatitudeAverage] = useState(0);
    const[longitudeAverage, setLongitudeAverage] = useState(0);
    
    //-----------------STEP 1-----------------
    //GET request to get all the groups names that available to display in the Select option and to set it to the groupsNames array:
    //This function called only once at the start to display the user all the avilable groups. we should use hear with useEffect with empty array as dependency
    
    //******************************************************************* */
    ///THIS WILL BE IN COMMENT TILL WE FIX THE ISSUE WITH THE SERVER
    // const getGroupsNames = async () => {
    //   console.log(`TEST`)
    //   const requestGroupsNames = await axios.get(process.env.REACT_APP_GROUPS_NAMES)
    //   .then((response) => response)
    //   .then((data) => {
    //    setGroupsNames((data.data));
    //   //  message.success(`Login Success`,2);
    //    })
    //   .catch(error => {
    //    message.error(`Failed to get data: ${error}`,5);
    //    console.error(error);
    //     })
    //  };
    // //Calling the getGroupsNames() only once!
    //  useEffect(()=>{
    //   getGroupsNames();
    //  },[]);
    //****************************************************************** */

    //-----------------STEP 1 END-----------------
    
    
    //  In the future we will change the GET request with USERNAME and PASSWORD....If EVERYTHING IS ALRIGHT WE WILL CONTINUE IN THE CODE...ELSE WE WILL REPEAT THE PROCCESS AND SHOW ERROR GIF
    
    //-----------------STEP 2-----------------
    
    // This is the selected group name from the Select option
    const groupName = (value) => {
      // console.log(`selected ${value}`);
      setChosenGroup(value);//Here we set the chosen group name and send it to the varaible
     let passwordForGroup = prompt(`Insert password for ${value}:`)
    
     switch (passwordForGroup) {
      case null:
      case "":
      case " ":
      case undefined:
           {
        message.error(`Please insert password`,5);
        console.error("Incorrect or invalid password");
        setChosenGroup(null);
        break;
        }
      default:{
        setPassword(passwordForGroup);
      }
    }
    };
    
    //After we setting the chosen group and password we call the function getImeiByGroupName(chosenGroup,password) and starting the proccess
    useEffect(() => {
    if (chosenGroup !== '' && password !== '') {
      message.loading(`Sending data...`, 1);
      getImeiByGroupNameAndPassword(chosenGroup, password);
    }
    }, [chosenGroup, password]);
    
    //-----------------STEP 2 END-----------------
    
    //-----------------STEP 3-----------------
    
    const getImeiByGroupNameAndPassword = async (chosenGroup,password) => {
      setShowMarkers(true);//show us the markers on the map!!
      // console.log(`the chosen group is: ${chosenGroup} and the password is ${password}`)
     const requestGroups = await axios.get(process.env.REACT_APP_REQUEST_GROUPS_OLD+`${chosenGroup}`)
     .then((response) => response)
     .then((data) => {
      //  console.log(data)
      setDevicesImeiArray(JSON.parse(data.data.imei_list));
      message.success(`Login Success`,1);
      })
     .catch(error => {
      message.error(`Failed to get data: ${error}`,5);
      console.error(error);
       })
    };
    
     //-----------------STEP 3 END-----------------
    
      //Here we run over the IMEI ARRAY and for each IMEI we use GET method to recive its own data
      //-----------------STEP 4-----------------
      useEffect(() => {
        const requests = devicesImeiArray.map(async deviceImei => await axios.get(process.env.REACT_APP_DEVICES_IMEIS+`${deviceImei}`)); 
        if(requests.length){
        setTimeout(() => {
          getData(requests);
        }, 2000);}
      }, [devicesArray , devicesImeiArray]);
    
    
      const getData = (requests) => {
        axios
          .all(requests)
          .then(responses => {
           setDevicesArray(responses);//Here we setting all the information for each device 
          //  console.log(devicesArray.length);
          })
          .catch(error => {
            console.error(error);
            message.error(`Failed to get data to devices array: ${error}`,5);
          });
      };
    
     //-----------------STEP 4 END-----------------


     const initialRegionByGroup = (devicesArray) => { 
      let latSum = 0, lngSum = 0, counter = 0;
      if(devicesArray.length > 0){
        for (let i = 0; i < devicesArray.length; i++) {
          latSum += parseFloat(devicesArray[i].data.lat);//converting to numbers
          lngSum += parseFloat(devicesArray[i].data.lng);//converting to numbers
          counter++;
        }
        latSum = latSum/counter;
        lngSum = lngSum/counter;
        }
        if(lngSum - latSum < 13 && latSum - lngSum < 13){//if the distance between the longitude to the latitude is small so initial the map to it
          setLatitudeAverage(latSum);
          setLongitudeAverage(lngSum);
          // console.log(`Success, the distance between the longitude to the latitude is small`)
        }
        else{//if the distance between the longitude to the latitude is too big so initial the map to it
          setLatitudeAverage(32.096234);
          setLongitudeAverage(34.847722);
          // console.log(`Fail, the distance between the longitude to the latitude is too big`)
        }
      };

      useEffect(() => {
        initialRegionByGroup(devicesArray);
      }, [devicesArray]);
    
      // Zoom out to view the entire group by a function that calculates an average of positions every few seconds
    const zoomOutToGroup = () => {
      setCenter({lat:latitudeAverage,lng:longitudeAverage});
      setZoom(8);
    };

    //This is the default size of the map in our Map page
    const containerStyle = {
      width: '100%',
      height: '100vh'
    };
    
    const [activeMarker, setActiveMarker] = useState(null);
    
    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };
    
    
    const handleZoomChanged = (e) => {
     // console.log(`CURRENT ZOOM: ${e}`)//this refers to Google Map instance
        setZoom(e.map.zoom);
    };

    const handleCenterChanged = (e) => {
    //  console.log(`temp center: ${e.detail.center}`)//this refers to Google Map instance
        setCenter(e.detail.center);
     };

    //This function set the view of the map to focus on the marker we clicked
    const handleClickActiveMarker = (marker) => {
      // console.log(`zoom${zoom}`)
      setCenter({lat:JSON.parse(marker.lat),lng:JSON.parse(marker.lng)}); // set the center of the map to be the position of the clicked marker
      setZoom(15)// set the zoom level of the map to 14
    };
    
    ///This for display specific place on the map by cordinates
    const [showCoordinatesInput, setShowCoordinatesInput] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    //Display Serach Cordinates Input View
    const displaySearchCordinates = () =>{
    setShowCoordinatesInput(previousState => !previousState);
    };
  
    const handleSearchByCordinates = () => {
      // Check if latitude and longitude are valid numbers
      if (isNaN(latitude) || isNaN(longitude) || latitude.includes(" ") || longitude.includes(" ") || latitude.length === 0 || longitude.length === 0 || latitude.charAt(0)=== "." || longitude.charAt(0) === ".") {
      alert('Please enter valid numbers for latitude and longitude.');
      setLatitude('');
      setLongitude('');
      return;
      }
      // console.log('Search:', latitude, longitude);
      setCenter({lat:parseFloat(latitude),lng:parseFloat(longitude)}); 
      setZoom(15)
      setLatitude('');
      setLongitude('');
      setShowCoordinatesInput(false);
    };

     //Here we will delete all the current information:
     const deleteInformation = () => {
      //  console.log(`Delete information`)
       setChosenGroup("");
       setPassword("");
       setDevicesImeiArray([]);
       setDevicesArray([]);
       setZoom(5);
       setCenter({lat: 32.096234, lng: 34.847722});
       setActiveMarker(null);
       setShowMarkers(false);
    };

    
  return (
    <>
        <div className='subHeaderContainer'>
                  <Button className='clearMapButtonContainer' icon={<ClearOutlined className='clearMapButtonIcon'/>} onClick={ () => deleteInformation()} ><span>Clear Map</span></Button>
                <div><br></br><span className='currentGroupHeader'>Current Group : {chosenGroup}</span>
                <span className='chooseGroupHeader'> Choose your group : </span> &nbsp;
                {/* Here we will show all the groups that the user can choose */}
                <Select 
                placeholder="Select Group"
                value={chosenGroup}
                onChange={groupName}
                style={{ width: 150 }}
                >
                {(userName === 'ARMONIC' ? groupsForGuestNames : groupsNames2)?.map((group, index) => {
                    return <Select.Option value={group} key={index}>{group}</Select.Option>
                })}
                </Select>
                <span className='deviceStatusPosition'><DevicesStatus devicesArray={devicesArray}/></span>
                <br/><br/> &nbsp;&nbsp;
            
            {showCoordinatesInput && (
                <div>
                  <input
                    type="text"
                    placeholder="Latitude"
                    className='inputCoordinatesStyle'
                    value={latitude}
                    maxLength={15}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Longitude"
                    className='inputCoordinatesStyle'
                    value={longitude}
                    maxLength={15}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                  <Button onClick={handleSearchByCordinates}>Search<SearchOutlined className='serachCoordinatesButtonIcon'/></Button>
                </div>
            )}
            </div>

        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Map
             mapId={"a1530c1f49c95f3a"}
             style={containerStyle}
             zoom={zoom}
             center= {center}
             cameraControl={true}
             clickableIcons={false}
             streetViewControl={false}
             zoomControl= {true}
             rotateControl={true}
             overviewMapControl={true}
             scaleControl={true}
             mapTypeControl={true}
             onBoundsChanged={(e) => handleCenterChanged(e)}
             onZoomChanged={(e) => handleZoomChanged(e)}
            >
              
            <div>
            {
             showMarkers && devicesArray?.map((device,index) => {
                // console.log(`IMEI: ${device.config.url.substring(device.config.url.length-15)}`)
                // console.log(`Name: ${device.data.driver_name}`)
                // console.log(`lat: ${device.data.lat}`)
                // console.log(`lng: ${device.data.lng}`)
                // console.log(`color: ${device.data.color}`)
                // console.log(`Date: ${new Date(device.data.timestamp*1000).toLocaleString()}`)
                return (
                  <div key={index}>
                    {/* <AdvancedMarker  position={{lat: parseFloat(device.data.lat), lng: parseFloat(device.data.lng)}} clickable={true} draggable={false} onClick={() => {handleActiveMarker(device.config.url.substring(device.config.url.length-15));handleClickActiveMarker(device.data)}} style={{ transform: 'translate(-50%, -50%)' }}> */}
                    <AdvancedMarker position={{lat: parseFloat(device.data.lat), lng: parseFloat(device.data.lng)}} clickable={true} draggable={false} onClick={() => {handleActiveMarker(device.config.url.substring(device.config.url.length-15));handleClickActiveMarker(device.data)}}>
                        <span><img alt='markerIcon' src={`./${MarkerColor(device)}.png`}/></span>
                        {activeMarker === device.config.url.substring(device.config.url.length-15) ? (
                        <InfoWindow  onCloseClick={() => setActiveMarker(null)} position={{lat: parseFloat(device.data.lat), lng: parseFloat(device.data.lng)}}>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>ID: <span style={{ fontWeight: 'normal' }}>{device.config.url.substring(device.config.url.length-15)}</span></h6>
                                <h6 style={{ fontWeight: 'bold' }}>Name: <span style={{ fontWeight: 'normal' }}>{device.data.driver_name}</span></h6>
                                <h6 style={{ fontWeight: 'bold' }}>Color: <span style={{ fontWeight: 'normal' }}>{device.data.color} <div style={{backgroundColor:`${MarkerGroupColor(device)}`,height:"10px",width:"20px",borderRadius:5,display:"inline-block"}}></div></span></h6>
                                <h6 style={{ fontWeight: 'bold' }}>lat: <span style={{ fontWeight: 'normal' }}>{device.data.lat}</span></h6>
                                <h6 style={{ fontWeight: 'bold' }}>lng: <span style={{ fontWeight: 'normal' }}>{device.data.lng}</span></h6>
                                {/* coverting the timestamp to local time string ***must multiply 1000 */}
                                <h6 style={{ fontWeight: 'bold' }}>Date: <span style={{ fontWeight: 'normal' }}>{new Date(device.data.timestamp*1000).toLocaleString() }</span></h6>  
                            </div>
                        </InfoWindow>
                         ) : null}
                    </AdvancedMarker>
                </div>)
              })
            }
                <Button className='zoomOutToAllButton' onClick={zoomOutToGroup}>
                    <img src={'./markersLocations.png'} alt='markers locations'/>
                </Button>
                <Button className='searchLocationButton' onClick={() => displaySearchCordinates(true)}>
                    <img src={'./searchLocation.png'} alt='search locations'/>
                </Button>
            </div>
            </Map>
        </APIProvider>
        </div>
    </>
  )
};
