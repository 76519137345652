import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CompassOutlined, DatabaseOutlined, LogoutOutlined, CarOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { UserContext } from './Components/UserContext';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';

export default function NavBar() {
  const { loggedIn, setLoggedIn, userName } = useContext(UserContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleLogout = () => {
    setLoggedIn(false);
    navigate('/');
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      {loggedIn && (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#1A237E' }}>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* Maps Dropdown */}
            <li
              className="nav-item"
              onMouseEnter={handleDropdownOpen}
              onMouseLeave={handleDropdownClose}
            >
              <span className="nav-link" style={{ cursor: 'pointer', color: "#449D97" }}>
                <CompassOutlined /> Maps
              </span>
              {isDropdownOpen && (
                <div className="dropdown-menu" style={{ display: 'block',color: "#449D97", }}>
                  <Link to="/map" className="dropdown-item" style={{ cursor: 'pointer', color: "#449D97" }}>
                    <CompassOutlined style={{ cursor: 'pointer', color: "#449D97" }}/> Map
                  </Link>
                  <Link to="/3dmap" className="dropdown-item" style={{ cursor: 'pointer', color: "#449D97" }}>
                    <ThreeDRotationIcon style={{ cursor: 'pointer', color: "#449D97" }}/> 3D Map
                  </Link>
                  <Link to="/mapBoxTerrin" className="dropdown-item" style={{ cursor: 'pointer', color: "#449D97" }}>
                    <PublicSharpIcon style={{ cursor: 'pointer', color: "#449D97" }}/> Terrain 3D Map
                  </Link>
                </div>
              )}
            </li>
             {/* http://localhost:3000/showMarkers */}
            <li className="nav-item">
              <Link className="nav-link" to="/showMarkers" style={{color:"#449D97"}}>
                <DatabaseOutlined style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} /> Show Markers</Link>
            </li>
            {/* Conditionally render Jene */}
            {userName !== 'ARMONIC' && (
              <li className="nav-item">
                <Link className="nav-link" to="/jene" style={{ color: "#449D97" }}>
                  <CarOutlined style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} /> Jene
                </Link>
              </li>
            )}
            <li className="nav-item">
                <Link className="nav-link" to="/mgConnect" style={{ color: "#449D97" }}>
                  <DeploymentUnitOutlined  style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} /> MG Connect
                </Link>
            </li>
          </ul>
          <div>
            <img
              src="MobileGroupLogoNavBar.png"
              style={{ width: '150px', height: '50px', position: 'relative', right: '30px', display: 'flex' }}
              alt="mobileGroupLogo"
            />
          </div>
          <button className="btn btn-outline-light" style={{color:"#449D97",borderColor:"#449D97"}} onClick={handleLogout}>
            <LogoutOutlined /> Logout
          </button>
        </nav>
      )}
    </>
  );
}
