import React, { useState, } from 'react';
import { message } from 'antd';
import MgConnectMenu from '../Components/MgConnect/MgConnectMenu';
import MgConnectStatistics from '../Components/MgConnect/MgConnectStatistics';
import MgConnectDeviceCommand from '../Components/MgConnect/MgConnectDeviceCommand';
import MgConnectDeviceInformation from '../Components/MgConnect/MgConnectDeviceInformation';
import MgConnectGpsLocation from '../Components/MgConnect/MgConnectGpsLocation';
import MgConnectDeviceConfiguration from '../Components/MgConnect/MgConnectDeviceConfiguration';
import MgConnectMessageLog from '../Components/MgConnect/MgConnectMessageLog';
import MgConnectDeviceSelection from '../Components/MgConnect/MgConnectDeviceSelection';
import './MgConnect.css';

export default function MgConnect() {
  const [currentMenuItem, setCurrentMenuItem] = useState('monitor');
  const [imeiToSearch, setImeiToSearch] = useState('');
  const [deviceInformation, setDeviceInformation] = useState([]);
  const [deviceGpsLocation, setDeviceGpsLocation] = useState([]);
  const [deviceConfiguration, setDeviceConfiguration] = useState([]);
  const [deviceMessageLog, setDeviceMessageLog] = useState([]);
  

  const onFinish = (values) => {
    if (containsOnlyDigits(values.requiredImei.trim())) {
      setImeiToSearch(values.requiredImei);
      setDeviceInformation([]);
      fetchData(values.requiredImei);
    } else {
      return message.error('The IMEI should only contain numbers');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setImeiToSearch('');
    setDeviceInformation([]);
  };

  const fetchData = async (imei) => {
    try {
      console.log(`fetch data for ${imei}`);
    } catch (error) {
      console.log(error)
      message.error(error);
    }
  };

  const containsOnlyDigits = (str) => {
    return /^\d+$/.test(str);
  };

  const clearData = () => {
    setImeiToSearch(''); 
    setDeviceInformation([]); 
  };

  const handleMenuClick = (e) => {
    setCurrentMenuItem(e.key);
    if (currentMenuItem === 'monitor'){
    }
    if (currentMenuItem === 'statistics'){
      setImeiToSearch("");
    }
  };

  return (
    <div>
    <MgConnectMenu currentMenuItem={currentMenuItem} handleMenuClick={(e) => handleMenuClick(e)}/>

    {currentMenuItem === "monitor" && (
      <>
        <div className='deviceSelectionAndCommandContainer'>
          <MgConnectDeviceSelection imeiToSearch={imeiToSearch} onFinish={onFinish} onFinishFailed={onFinishFailed} clearData={clearData}/>
          <MgConnectDeviceCommand chosenImei={imeiToSearch}/>
        </div>
        <div className="deviceInformationAndGpsLocationAndConfigurationContainer">
          <div className="leftContainer">
            <MgConnectDeviceInformation />
            <MgConnectDeviceConfiguration />
          </div>
          <div className="rightContainer">
            <MgConnectGpsLocation />
          </div>
        </div>
        <MgConnectMessageLog/>
      </>
    )}

    {currentMenuItem === "statistics" && (
      <>
        <MgConnectStatistics/>
      </>
    )}
    </div>
  );
};
