import React from 'react';
import { Menu } from 'antd';

export default function MgConnectMenu({ currentMenuItem, handleMenuClick }) {

    const items = [
        { label: 'Monitor 📟', key: 'monitor' },
        { label: 'Statistics 📊', key: 'statistics' },
      ];

  return (
     <Menu
          onClick={handleMenuClick}
          selectedKeys={[currentMenuItem]}
          mode="horizontal"
          items={items}
          style={{ justifyContent: 'center' }}
        />
  )
}
