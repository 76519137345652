import React, { useState } from 'react';
import { Button, message, } from 'antd';
import { DownloadOutlined, SendOutlined, PoweroffOutlined, SettingOutlined, } from '@ant-design/icons';
import TerminalIcon from '@mui/icons-material/Terminal';
import "../MgConnect/MgConnectDeviceCommand.css";


export default function MgConnectDeviceCommand({ chosenImei }) {
    const [status, setStatus] = useState({ state: '', message: '' });

    const updateStatus = (newState, newMessage) => {
        setStatus({ state: newState, message: newMessage });
    };
    
    const pingDevice = () => {
        console.log(`pingDevice func()`);
        updateStatus('submitting', 'Pinging device...');
    };
    
    const getLatestData = () => {
        console.log(`getLatestData func()`);
        updateStatus('submitting', 'Getting latest data...');
    };
    
    const getConfig = () => {
        console.log(`getConfig func()`);
        updateStatus('submitting', 'Getting config...');
    };
    
    const resetDevice = () => {
        console.log(`resetDevice func()`);
        updateStatus('submitting', 'Resetting device...');
    };


  return (
     <div className='deviceCommandSection'>
        <h4 className='deviceCommandHeader'><TerminalIcon/> Device Commands</h4>
        <div className='deviceCommandButtonsSection'>
            <Button style={{ backgroundColor: chosenImei ? '#13C2C2' : 'grey', color: "white", border: 'none',}} variant="solid" disabled={chosenImei ? false : true } onClick={pingDevice}><SendOutlined/>Ping Device</Button>
            <Button style={{ backgroundColor: chosenImei ? '#13C2C2' : 'grey', color: "white", border: 'none',}} variant="solid" disabled={chosenImei ? false : true } onClick={getLatestData}><DownloadOutlined/>Get Latest Data</Button>
            <Button style={{ backgroundColor: chosenImei ? '#13C2C2' : 'grey', color: "white", border: 'none',}} variant="solid" disabled={chosenImei ? false : true } onClick={getConfig}><SettingOutlined />Get Config</Button>
            <Button style={{ backgroundColor: chosenImei ? 'red' : 'grey', color: "white", border: 'none',}} variant="solid" disabled={chosenImei ? false : true } onClick={resetDevice}><PoweroffOutlined/>Reset Device</Button>
        </div>
    </div>
  )
};
