import React from 'react';
import { Button, Select } from 'antd';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import "../Jene/SearchByModel.css";
const { Option } = Select;

export default function SearchByModel({ modelsNames, selectedModel, onSelectChange, onRefresh, onSearch }) {

  return (
    <div className='inputFormPosition'>
    <p>Choose Model:&nbsp;</p>
    <Select 
      style={{ width: '30%', marginBottom: '16px' }}
      placeholder="Select your model"
      onChange={onSelectChange}
      value={selectedModel}
    >
      {modelsNames.map((model, index) => (
        <Option key={index} value={model}>{model}</Option>
      ))}
    </Select>
    <Button type="primary" className='refreshModelsNamesButton' onClick={onRefresh}><SyncOutlined /></Button>
    <Button type="primary" className='findByModelButton' onClick={() => onSearch(selectedModel)}>Find<SearchOutlined /></Button>
  </div>
  )
};

