import React from 'react';
import { Button, Form, Input, } from 'antd';
import { SearchOutlined, DeleteOutlined, } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import "../MgConnect/MgConnectDeviceSelection.css";

export default function MgConnectDeviceSelection({ imeiToSearch, onFinish, onFinishFailed, clearData}) {
    const [form] = useForm();

    const handleClearData  = () => {
      form.resetFields();
      clearData();
    };
    
  return (
    <div className='searchImeiSection'>
        <Form
          form={form}
          name="basic"
          className='searchImeiForm'
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          >
          <Form.Item
            label=""
            name="requiredImei"
            rules={[{ required: true, message: 'Please insert your IMEI' }]}
          >
            <div className='searchImeiActionSection'>
              <Input 
                placeholder='Insert IMEI'
                style={{ flex: 1, fontWeight: "bold",}}
                autoFocus={true} 
                maxLength={30}
                />
              <Button className='searchImeiFindButton' htmlType="submit" >Find<SearchOutlined /></Button>
              <Button className='searchImeiClearDataButton' onClick={handleClearData}>Clear<DeleteOutlined/></Button>
            </div>
          </Form.Item>
          { imeiToSearch && 
          <div style={{textAlign:"center"}}>
            <h6>Data for IMEI: {imeiToSearch}</h6>
          </div>
          }
      </Form>
    </div>
  )
};
