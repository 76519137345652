import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd';
import { SearchOutlined, ClearOutlined, SwapOutlined } from '@ant-design/icons';
import axios from 'axios';
import "../Jene/UnitHistory.css"

export default function UnitHistory() {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [isDataReversed, setIsDataReversed] = useState(false);
    const [chosenImei, setChosenImei] = useState('');
    const [model, setModel] = useState('');

    
  const getData = async (value) => {
    setChosenImei(value.requiredImei);
    try {
      const response = await axios.get(`${process.env.REACT_APP_UNIT_LAST_HISTORY + value.requiredImei}`);
      const responseData = response.data;

      if (responseData.length > 0) {
        // Set the model from the first entry
        setModel(responseData[0].model);
        // Format the data for the table
        const formattedData = responseData.map((item) => ({
          time: new Date(item.last_check).toGMTString(), // Format to GMT
          fingerprint: item.version_fingerprint,
        }));
        setData(formattedData);
      } else {
        message.error("No data found!");
      }
    } catch (error) {
      // deleteInformation();
      console.clear();
      message.error("No data found!");
    }
  };

    const onFinishFailed = () => {};

    const reversData = () => {
      setIsDataReversed(!isDataReversed);
      setData(prevData => [...prevData].reverse());
    };

    const deleteInformation = () => {
        console.clear();
        setData([]);
        setChosenImei('');
        setModel('');
        setIsDataReversed(false); 
        form.resetFields();
    };
    
  return (
    <>
    <div className='inputFormPosition'>
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 800, width: '100%' }}
      initialValues={{ remember: true }}
      autoComplete="off"
      clearOnDestroy={true}
      onFinish={getData}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Insert IMEI"
        name="requiredImei"
        rules={[{ required: true, message: 'Please insert your IMEI' }]}
      >
        <div style={{ display: 'flex' }}>
          <Input style={{ flex: 1 }} autoFocus={true} />
          <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>Find<SearchOutlined /></Button>
          <Button style={{ borderColor: "#E74C3C", backgroundColor:"#E74C3C", color: "white", right:"-5px"}} onClick={ () => deleteInformation()} >Clear<ClearOutlined/></Button>
        </div>
       
      </Form.Item>
    </Form>
  </div>

    {data.length > 0 ? (
        <div className='smallHeader'>
            <p>IMEI: {chosenImei}</p>
            <p>Model: {model}</p>
            <p>(Last 500 readings of the device)</p>
        </div>

    ) : (null)}

  {data.length > 0 ? (
    <div className="outer-container">
        <table className="device-table">
            <thead>
                <tr>
                    <th className="timeHeader">
                    <span className='timeHeaderSpan'>TIME</span>
                    <Button className='timeHeaderButton' onClick={reversData}><SwapOutlined /></Button></th>
                    <th className="table-header">FINGERPRINT</th>
                </tr>
            </thead>
            <tbody>
                {data.map((device, index) => (
                    <tr key={index}>
                        <td className="table-cell">{device.time}</td>
                        <td className="table-cell">{device.fingerprint}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    ) : (null)
    }

  </>
  )
};
