import React, { useState } from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DateRange from '../DateRange';
import "../MgConnect/MgConnectMessageLog.css";

export default function MgConnectMessageLog() {

  const [messageLogData, setMessageLogData] = useState([
    { timestamp: "1704462645", data: "Device startup", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462645", data: "Device startup", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462645", data: "Device startup", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462689", data: "GPS fix acquried", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462620", data: "CAN bus connected", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462620", data: "CAN bus connected", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462620", data: "CAN bus connected", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462620", data: "CAN bus connected", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
    { timestamp: "1704462620", data: "CAN bus connected", coordintates: "Lat: 32.4235435 , Lng: 34.4235435" },
   
  ]);

 

  return (
    <div className='deviceMessageLog'>
      <h4 className='deviceMessageLogHeader'><AssignmentOutlinedIcon /> Message Log</h4>
      <div className="dateRangeContainer">
        <DateRange />
      </div>
      <div className='deviceMessageLogContainer'>
        <div className='deviceMessageLogTableWrapper'>
          <table className='deviceMessageLogTable'>
            <thead>
              <tr>
                <th scope="col" className="table-header">TIMESTAMP (UTC)</th>
                <th scope="col" className="table-header">DATA</th>
                <th scope="col" className="table-header">Coordinates</th>
              </tr>
            </thead>
            <tbody>
              {messageLogData.map((row, index) => (
                <tr key={index} className="table-row">
                  <td className="table-cell">{row.timestamp}</td>
                  <td className="table-cell">{row.data}</td>
                  <td className="table-cell">{row.coordintates}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
