import React, { useState } from 'react';
import { DatePicker, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

export default function DateRange() {
    const [chosenDate, setChosenDate] = useState([]);
    // Define min and max date for the RangePicker
    const minDate = dayjs().subtract(14, 'day'); // 14 days ago
    const maxDate = dayjs(); // Today

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setChosenDate([dateStrings[0],dateStrings[1]]);
    } else {
      setChosenDate([]);
      console.log('Clear');
    }
  };

  const rangePresets = [
    {
      label: 'Last 24 Hours',
      value: [dayjs().subtract(24, 'hours'), dayjs()],
    },
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    }
  ];

  const searchDataByChosenDate = () => {
    if(!chosenDate.length){
      console.log(`not send`)
      return;
    } else {
      //fetch data between the chosen date and time
      console.log(`fetch data between ${chosenDate}`);
      
    }
  };
  
  return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <RangePicker
          presets={[
            {
              label: <span aria-label="Current Time to Start of Day">Today start ~ Now</span>,
              value: () => [dayjs(), dayjs().startOf('day')],
            },
            ...rangePresets,
          ]}
          showTime
          format="YYYY/MM/DD HH:mm:ss"
          minDate={minDate}
          maxDate={maxDate}
          onChange={onRangeChange}
        />
        <Button type='primary' onClick={() => searchDataByChosenDate()}>Search <SearchOutlined /></Button>
      </div>
  );
};
