import React, { useState, } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { APIProvider, Map, AdvancedMarker, } from '@vis.gl/react-google-maps';
import "../MgConnect/MgConnectGpsLocation.css";

export default function MgConnectGpsLocation() {
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({lat:32.0966275, lng: 34.8481448});


    const handleZoomChanged = (e) => {
        // console.log(`CURRENT ZOOM: ${e}`)//this refers to Google Map instance
           setZoom(e.map.zoom);
       };
   
       const handleCenterChanged = (e) => {
       //  console.log(`temp center: ${e.detail.center}`)//this refers to Google Map instance
           setCenter(e.detail.center);
        };

  return (
    <div className='deviceGpsLocationSection'>
      <h4 className='deviceGpsLocationHeader'><LocationOnOutlinedIcon/> GPS Location</h4>
        <h6 style={{gap: "10px", margin: "20px" }}>Last Known Position: <span style={{fontWeight:"lighter"}}>&nbsp;&nbsp;Lat: 32.4235435 , Lng: 34.4235435</span></h6>
        <div className='deviceGpsLocationMapContainer'>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Map
             mapId={"a1530c1f49c95f3a"}
             style={{width:"100%", height:"70%"}}
             zoom={zoom}
             center={center}
             cameraControl={false}
             clickableIcons={false}
             streetViewControl={false}
             zoomControl= {true}
             rotateControl={true}
             overviewMapControl={false}
             scaleControl={true}
             mapTypeControl={false}
             keyboardShortcuts={false}
             onBoundsChanged={(e) => handleCenterChanged(e)}
             onZoomChanged={(e) => handleZoomChanged(e)}
            >
              <AdvancedMarker position={{lat:32.0966275, lng: 34.8481448}} clickable={true} draggable={false}>
                
              </AdvancedMarker>
            </Map>
          </APIProvider>
        </div>
    </div>
  )
};
