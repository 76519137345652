import React from 'react';
import { createRoot } from 'react-dom/client';
import { createHashRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import { UserProvider, UserContext } from './Components/UserContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './NavBar';
import Home from './screens/Home';
import Map from './screens/Map';
import ShowMarkers from './screens/ShowMarkers';
import SignIn from './screens/SignIn';
import Test from './screens/Test';
import MapBoxTerrin from './screens/MapBoxTerrin';
import Jene from './screens/Jene';
import MgConnect from './screens/MgConnect';

// Private route wrapper
function PrivateRouteWrapper() {
  const { loggedIn } = React.useContext(UserContext);

  return loggedIn ? (
    <>
      <NavBar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace />
  );
}

// Routes definition
const router = createHashRouter(
  [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/signIn',
      element: <SignIn />,
    },
    {
      element: <PrivateRouteWrapper />,
      children: [
        { path: '/map', element: <Map /> },
        { path: '/3dmap', element: <Test /> },
        { path: '/showMarkers', element: <ShowMarkers /> },
        { path: '/mapBoxTerrin', element: <MapBoxTerrin /> },
        { path: '/jene', element: <Jene /> },
        { path: '/mgConnect', element: <MgConnect/> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true, // Enables relative paths in nested routes
      v7_fetcherPersist: true,   // Retains fetcher state during navigation
      v7_normalizeFormMethod: true, // Normalizes form methods (e.g., POST or GET)
      v7_partialHydration: true, // Supports partial hydration for server-side rendering
      v7_skipActionErrorRevalidation: true, // Prevents revalidation when action errors occur // Opt-in to startTransition wrapping
    },
  }
);

// Render the app
const rootElement = document.getElementById('root');
createRoot(rootElement).render(
  <React.StrictMode>
    <UserProvider>
      <RouterProvider future={{ v7_startTransition: true }} router={router} />
    </UserProvider>
  </React.StrictMode>
);
