import React, { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import "../MgConnect/MgConnectDeviceConfiguration.css";

export default function MgConnectDeviceConfiguration() {

  const [deviceConfigurationData] = useState([
    { parameter: "numberOfBeeps", value: "5" },
    { parameter: "disableKeypad", value: "no" },
    { parameter: "updateInterval", value: "300" },
    { parameter: "gpsInterval", value: "60" },
    { parameter: "numberOfBeeps", value: "5" },
    { parameter: "disableKeypad", value: "no" },
    { parameter: "updateInterval", value: "300" },
    { parameter: "gpsInterval", value: "60" },
  ]);

  return (
    <div className="deviceConfigurationSection">
      <h4 className="deviceConfigurationHeader"><SettingsOutlinedIcon /> Device Configuration</h4>
      <div className="deviceConfigurationTableSection">
        <div className="table-wrapper">
          <table className="deviceConfigurationTable">
            <thead>
              <tr>
                <th className="table-header">PARAMETER</th>
                <th className="table-header">VALUE</th>
              </tr>
            </thead>
            <tbody>
              {deviceConfigurationData.map((row, index) => (
                <tr key={index} className="table-row">
                  <td className="table-cell">{row.parameter}</td>
                  <td className="table-cell">{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
