import React, { useState } from 'react';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import MemoryIcon from '@mui/icons-material/Memory';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "../MgConnect/MgConnectDeviceInformation.css";

export default function MgConnectDeviceInformation() {


  return (
    <div className='deviceInformationSection'>
      <h4 className='deviceInformationHeader'><InfoOutlinedIcon/> Device Information</h4>
      <div className='deviceInformationContainer'>
        <h6><Battery0BarIcon/> Battery: <span style={{fontWeight:"lighter"}}>85%</span></h6>
        <h6 style={{fontWeight:"bolder"}}><MemoryIcon/> SW Version: <span style={{fontWeight:"lighter"}}>v2.1.3</span></h6>
        <h6 style={{fontWeight:"bolder"}}><Grid3x3Icon/> HW Version: <span style={{fontWeight:"lighter"}}>v1.0</span></h6>
        <h6 style={{fontWeight:"bolder"}}><SpeedIcon/> Odometer: <span style={{fontWeight:"lighter"}}>12345 km</span></h6>
        <h6 style={{fontWeight:"bolder"}}><CandlestickChartOutlinedIcon/> CAN Date: <span style={{fontWeight:"lighter"}}>Engine RPM: 2500, Speed: 60km/h</span></h6>
        <h6 style={{fontWeight:"bolder"}}><AccessTimeIcon/> Last Communication: <span style={{fontWeight:"lighter"}}>2025-01-07 15:30:45</span></h6>
        <h6 style={{fontWeight:"bolder"}}><LocationOnOutlinedIcon/> Last GPS Update: <span style={{fontWeight:"lighter"}}>2025-01-07 15:30:40</span></h6>
      </div>
    </div>
  )
};
